import './Footer.css';
import peerling_agb from "./media/peerling_agb.pdf";

function Footer() {
  return (
    <div className="Footer">
      <ul>
        <li className="Copyright">Copyright © 2021 Peerling</li>
        <li className="Address">TBZ FA Ausstellungstrasse 70, 8090 ZH</li>
        <li className="Contact"><a href='mailto:info@peerling.ch'>info@peerling.ch</a></li>
        <li className="AGB">Mit dem Besuch der Website erklären Sie sich mit den <a href={peerling_agb} target={"_blank"} rel='noopener noreferrer'>Allgemeine Geschäftsbedingungen</a> einverstanden.</li>
      </ul>
    </div>
  );
}

export default Footer;
