import './Menu.css';
import { Link } from "react-scroll";

function Menu() {
    return (
      <div className="Menu">
        <ul>
          <li>
            <Link
              className='menu-link'
              activeClass="active"
              to="start"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}>
                Start
            </Link>
          </li>
          <li>
            <Link
              className='menu-link'
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}>
                Peerling
            </Link>
          </li>
          <li>
            <Link
              className='menu-link'
              activeClass="active"
              to="team"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}>
                Team
            </Link>
          </li>
          <li>
            <Link
              className='menu-link'
              activeClass="active"
              to="status"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}>
                Get Peer
            </Link>
          </li>
        </ul>
      </div>
    );
  }
  
  export default Menu;
  