import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import MetaTags from 'react-meta-tags';

ReactDOM.render(
  <React.StrictMode>
    <MetaTags>
      <meta name="description" content="Peerling - soziales Netzwerk für Lehlinge" />
      <meta name="keywords" content="peerling peerling.ch social newtwork socialnetwork sozial sozialenetzwerk TBZ" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="og:url" content="https://peerling.ch/index.html" />
      <meta name="og:locale" content="de_CH" />
      <meta name="og:type" content="website" />
      <meta name="og:title" content="Willkommen bei peerling.ch." />
      <meta name="og:description" content="Peerling - soziales Netzwerk für Lehlinge" />
    </MetaTags>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
