import './App.css';
import { Parallax } from "react-parallax";
import LazyLoad from 'react-lazyload';
import Footer from './Footer';
import Menu from './Menu';
import earth_image from "./media/images/earth-1756274.jpg";
import earth_image_small from "./media/images/small/earth-gc15606814_1280.jpg";
import planet_image from "./media/images/planet-5570055.jpg";
import planet_image_small from "./media/images/small/planet-gb9c977396_1280.jpg";
import peerling_video_preview from "./media/videos/peerling_video_preview.jpg";
import peerling_team from "./media/images/peerling_team.png";

function App() {
  return (
    <div className="App">
      <Menu />
      <LazyLoad className="LazyLoad start">
        <Parallax
          bgImage={earth_image}
          bgImageSrcSet={earth_image_small}
          strength={200}
          renderLayer={(percentage) => (
            <div>
              <div
                style={{
                  background: `rgba(37, 87, 196, ${percentage * 1})`
                }} className="Content"
              />
            </div>
          )}
        >
          <div className="paralaxBG">
            <div className="insideBlock">
              <p className='first'>Peerling</p>
              <hr />
              <p className='second'>Peerling für Lehrling!</p>
            </div>
          </div>
        </Parallax>
      </LazyLoad>
      <LazyLoad className="LazyLoad  about">
        <Parallax
          strength={200}
          renderLayer={(percentage) => (
            <div>
              <div
                style={{
                  background: `rgba(37, 87, 196, ${percentage * 1})`
                }} className="Content"
              />
            </div>
          )}
        >
          <div className="paralaxBG">
            <div className="insideBlock">
              <p>Peerling - soziales Netzwerk für Lehlinge.</p>
              {/* <video className='Media' poster={peerling_video_preview} controls>
                <source src="https://player.vimeo.com/video/672778386?h=82bc86ac56" type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              <iframe className='Frame' title='Peerling' src="https://player.vimeo.com/video/672778386?h=82bc86ac56" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen />
            </div>
          </div>
        </Parallax>
      </LazyLoad>
      <LazyLoad className="LazyLoad  team">
        <Parallax
          bgImage={planet_image}
          bgImageSrcSet={planet_image_small}
          strength={200}
          renderLayer={(percentage) => (
            <div>
              <div
                style={{
                  background: `rgba(37, 87, 196, ${percentage * 1})`
                }} className="Content"
              />
            </div>
          )}
        >
          <div className="paralaxBG">
            <div className="insideBlock">
              <h1>Team</h1>
              <img className='Media' src={peerling_team} alt="Peerling Team" />
            </div>
          </div>
        </Parallax>
      </LazyLoad>
      <LazyLoad className="LazyLoad  status">
        <Parallax
          strength={200}
          renderLayer={(percentage) => (
            <div>
              <div
                style={{
                  background: `rgba(37, 87, 196, ${percentage * 1})`
                }} className="Content"
              />
            </div>
          )}
        >
          <div className="paralaxBG">
            <div className="insideBlock">
              <p>Derzeit Peerling Netz ist in der Entwicklung!</p>
              <img className='Media' src={peerling_video_preview} alt="Peerling" />
            </div>
          </div>
        </Parallax>
      </LazyLoad>
      <Footer />
    </div>
  );
}

export default App;
